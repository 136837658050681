import { Navigate } from 'react-router-dom'

import { isAuthenticated } from '../../api/auth'
import { useUserConfig } from '../../api/hooks'

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const { isFetching } = useUserConfig()

  if (isFetching) {
    return null
  }

  if (isAuthenticated()) {
    return children
  }

  return <Navigate to="/logout" replace />
}
export default RequireAuth
