import { Body1 } from '@opinary/ui-components'
import styled from 'styled-components'

export const Box = styled(Body1)`
  height: 100%;
  width: 100%;
  box-sizing: border-box;
`
export const Main = styled(Box)`
  background-color: ${({ theme }) => theme.colors.gray[5]};
  color: ${({ theme }) => theme.colors.gray[1]};
  min-height: 100vh;
  display: grid;
  grid-template-areas:
    'header header'
    'navigation main';
  grid-template-columns: 240px auto;
  grid-template-rows: 96px auto;
  overflow: hidden;
`

export const Container = styled(Box)`
  grid-area: main;
  overflow: auto;
`
