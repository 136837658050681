import styled from 'styled-components'

export const StyledIconText = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0 8px 8px;

  &:hover {
    color: ${({ theme }) => theme.colors.gray[1]};

    svg path {
      stroke: ${({ theme }) => theme.colors.gray[1]};
    }
  }

  svg path {
    stroke: ${({ theme }) => theme.colors.gray[2]};
  }
`

export const ListItemText = styled.span`
  margin-left: 12px;
`

export const IconWrapper = styled.div`
  min-width: 20px;
  text-align: center;
`
