import { Body2 } from '@opinary/ui-components'

import { TRANSLATIONS } from '../translations'
import { Errors } from '../types'
import { List, ListItem, Wrapper } from './style'

type Props = {
  errors: Errors
}

const ErrorMessage = ({ errors }: Props) => {
  return (
    <Wrapper>
      <Body2>{TRANSLATIONS.PASSWORD_REQUIREMENTS}</Body2>
      <List>
        <ListItem $fulfilled={errors.match}>
          {TRANSLATIONS.ERROR_PASSWORDS_DONT_MATCH}
        </ListItem>
        <ListItem $fulfilled={errors.length}>
          {TRANSLATIONS.ERROR_PASSWORD_TOO_SHORT}
        </ListItem>
        <ListItem $fulfilled={errors.specialChar}>
          {TRANSLATIONS.ERROR_SPECIAL_CHARACTER}
        </ListItem>
        <ListItem $fulfilled={errors.number}>
          {TRANSLATIONS.ERROR_NUMBER}
        </ListItem>
        <ListItem $fulfilled={errors.case}>{TRANSLATIONS.ERROR_CASE}</ListItem>
      </List>
    </Wrapper>
  )
}

export default ErrorMessage
