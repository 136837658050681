import { useQuery } from '@tanstack/react-query'

import { getAccountDetails } from '../auth.ts'
import { getCustomers } from '../rest/rest.service.ts'

const useCustomers = () => {
  const accountDetails = getAccountDetails()

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['customers'],
    queryFn: getCustomers,
    enabled: !!accountDetails,
  })

  return { data, isLoading, refetch }
}

export default useCustomers
