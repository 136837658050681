const loadValhallaRoutes = async () => {
  try {
    const { default: getValhallaRoutes } = await import('valhalla/getRoutes')
    return getValhallaRoutes
  } catch {
    return () => []
  }
}

export default loadValhallaRoutes
