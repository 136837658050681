import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'

import { setAccountDetails } from '../../../api/auth'
import { loginRequest } from '../rest.service'
import { TRANSLATIONS } from '../translations'
import { LoginError, LoginResponse } from '../types'

type Variables = {
  email: string
  password: string
}

type Props = {
  onSuccess: (data: LoginResponse) => void
  onError?: (error: any) => void
}

const useLogin = ({ onSuccess, onError }: Props) => {
  const [error, setError] = useState('')
  const { mutate: login, isPending } = useMutation<
    LoginResponse,
    LoginError,
    Variables
  >({
    mutationKey: ['login'],
    mutationFn: ({ email, password }) => loginRequest(email, password),
    onError: error => {
      const errorMessage = error.response?.data.errors[0]
      setError(errorMessage || TRANSLATIONS.ERROR.SERVER_ERROR)
      onError?.(errorMessage || TRANSLATIONS.ERROR.SERVER_ERROR)
    },
    onSuccess: data => {
      setAccountDetails(data.account)
      onSuccess(data)
    },
  })

  return { login, isLoading: isPending, error, reset: () => setError('') }
}

export default useLogin
