// import axios from 'axios'

// import { GATEWAY_API_URL } from '../../api/constants'
import { ResendLinkResponse, VerifyTokenResponse } from './types'

export async function verifyToken(_code: string) {
  // const API_VERIFY_URL = `${GATEWAY_API_URL}/v1/verify-token`
  // const response = await axios.post(
  //   API_VERIFY_URL,
  //   { code },
  //   { withCredentials: true }
  // )

  // return response.data

  const mockSuccessResponse = {
    success: true,
    message: 'Token verified successfully',
  }

  const mockErrorResponse = {
    success: false,
    message: 'Invalid token',
  }

  return new Promise<VerifyTokenResponse>(resolve => {
    setTimeout(() => {
      resolve(Math.random() > 0.5 ? mockSuccessResponse : mockErrorResponse)
    }, 1000)
  })
}

export async function resendLinkRequest() {
  // const API_RESEND_CODE_URL = `${GATEWAY_API_URL}/v1/resend-link`

  // const response = await axios.get(API_RESEND_CODE_URL, {
  //   withCredentials: true,
  // })

  // return response.data

  const mockSuccessResponse = {
    success: true,
    message: 'Link sent successfully',
  }

  return new Promise<ResendLinkResponse>(resolve => {
    setTimeout(() => {
      resolve(mockSuccessResponse)
    }, 1000)
  })
}
