import { Analytics, Block, Library, Revenue, Zap } from '@opinary/ui-components'

import { PERMISSIONS } from '../../constants'
import { TRANSLATIONS } from './translations'

export const engageNavItems = [
  {
    url: '/library',
    id: 'libraryLink',
    text: TRANSLATIONS.LIBRARY_LINK,
    icon: Library,
    validPermissions: [],
  },
  {
    url: `/dashboard/analytics`,
    id: 'analyticsLink',
    text: TRANSLATIONS.ANALYTICS_LINK,
    icon: Analytics,
    validPermissions: [PERMISSIONS.ANALYTICS, PERMISSIONS.ADMIN],
  },
  {
    url: `/dashboard/revenue`,
    id: 'revenueLink',
    text: TRANSLATIONS.REVENUE_LINK,
    icon: Revenue,
    validPermissions: [PERMISSIONS.REVENUE, PERMISSIONS.ADMIN],
  },
  {
    url: `/customer/blocklist`,
    id: 'blocklistLink',
    text: TRANSLATIONS.BLOCKLIST_LINK,
    icon: Block,
    validPermissions: [PERMISSIONS.AUTOMATION, PERMISSIONS.ADMIN],
  },
  {
    url: `/customer/automation-matched`,
    id: 'automationMatchedLink',
    text: TRANSLATIONS.AUTOMATION_MATCHED_LINK,
    icon: Zap,
    validPermissions: [PERMISSIONS.AUTOMATION, PERMISSIONS.ADMIN],
  },
]

export const understandNavItems = [
  {
    url: '/audience/campaigns',
    id: 'audienceCampaignsLink',
    text: TRANSLATIONS.AUDIENCE_CAMPAIGNS_LINK,
    icon: Library,
    validPermissions: [],
  },
  {
    url: '/dashboard/audience/analytics',
    id: 'audienceAnalyticsLink',
    text: TRANSLATIONS.AUDIENCE_ANALYTICS_LINK,
    icon: Analytics,
    validPermissions: [],
  },
  {
    url: '/library/audience',
    id: 'audienceLibraryLink',
    text: TRANSLATIONS.AUDIENCE_LIBRARY_LINK,
    icon: Library,
    validPermissions: [PERMISSIONS.AUDIENCE],
  },
]

export const conversionNavItems = [
  {
    url: `/dashboard/conversion`,
    id: 'conversionLink',
    text: TRANSLATIONS.CONVERSION_LINK,
    icon: Analytics,
    validPermissions: [PERMISSIONS.CONVERSIONS, PERMISSIONS.ADMIN],
  },
  {
    url: '/audience/conversion-campaigns',
    id: 'conversionCampaignsLink',
    text: TRANSLATIONS.CONVERSION_CAMPAIGNS_LINK,
    icon: Library,
    validPermissions: [],
  },
]
