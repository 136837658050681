import { useRef, useState } from 'react'

import CreateMenu from './CreateMenu/CreateMenu.tsx'
import { Button, ChevronDown, Title, Wrapper } from './index.style'
import { TRANSLATIONS } from './translations'

const Create = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const buttonRef = useRef<HTMLButtonElement>(null)

  const handleToggleMenu = () => {
    setIsMenuOpen(value => !value)
  }

  const onClose = () => {
    setIsMenuOpen(false)
  }

  return (
    <Wrapper>
      <Button
        data-testid="createButton"
        onClick={handleToggleMenu}
        ref={buttonRef}
      >
        <Title>{TRANSLATIONS.CREATOR_LINK}</Title>
        <ChevronDown $open={isMenuOpen} />
      </Button>
      {isMenuOpen && (
        <CreateMenu onClose={onClose} ignoreClickRefs={[buttonRef]} />
      )}
    </Wrapper>
  )
}

export default Create
