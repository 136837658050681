import { hasAccess } from '@opinary/ui-components'
import { Fragment, RefObject } from 'react'

import { getPermissions } from '../../../api/auth.ts'
import { createMenuItems } from '../constants.ts'
import { Menu, SubsectionTitle, SubsectionWrapper } from './CreateMenu.style.ts'
import MenuItem from './MenuItem.tsx'

type Props = {
  onClose: () => void
  ignoreClickRefs: RefObject<HTMLElement>[]
}

const CreateMenu = ({ onClose, ignoreClickRefs }: Props) => {
  const validPermissions = getPermissions()
  return (
    <Menu onClose={onClose} ignoreClickRefs={ignoreClickRefs}>
      {createMenuItems.map(({ title, items }) => {
        if (
          items.every(
            ({ permissions }) => !hasAccess(validPermissions, permissions)
          )
        )
          return null

        return (
          <Fragment key={title}>
            <SubsectionWrapper>
              <SubsectionTitle>{title}</SubsectionTitle>
              {items.map(
                ({ text, Icon, to, permissions }) =>
                  hasAccess(validPermissions, permissions) && (
                    <MenuItem
                      text={text}
                      Icon={Icon}
                      key={text}
                      to={to}
                      onClick={onClose}
                    />
                  )
              )}
            </SubsectionWrapper>
          </Fragment>
        )
      })}
    </Menu>
  )
}

export default CreateMenu
