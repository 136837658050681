import { Button } from '@opinary/ui-components'
import styled, { css } from 'styled-components'

export const Form = styled.form`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 100%;
  max-width: 379px;
  margin: 16px 0;
`

export const SubmitButton = styled(Button)(
  ({ disabled }) => css`
    margin-top: 16px;
    width: 100%;
    padding: 12px 24px;

    &:hover {
      cursor: ${disabled ? 'not-allowed' : 'pointer'};
    }
  `
)
