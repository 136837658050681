import {
  Button as ButtonComponent,
  Subtitle2 as Subtitle2Component,
} from '@opinary/ui-components'
import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 35px;
`

export const Button = styled(ButtonComponent)`
  width: 415px;
`

export const Subtitle2 = styled(Subtitle2Component)(
  ({ theme: { colors } }) => css`
    color: ${colors.gray[2]};
  `
)
