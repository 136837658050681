import {
  ContextMenu as ContextMenuComponent,
  Logo as LogoIcon,
} from '@opinary/ui-components'
import styled, { css } from 'styled-components'

import { StyledIconText } from '../IconText/index.style.ts'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  grid-area: header;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[3]};
  padding: 28px 32px;
`

export const SettingsButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.blue[0]};
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.gray[1]};
  &:hover {
    color: ${({ theme }) => theme.colors.gray[0]};
  }
`

export const ContextMenu = styled(ContextMenuComponent)`
  right: 14px;
  top: 78px;
  width: 192px;
`

export const Logo = styled(LogoIcon)(
  ({ theme: { colors } }) => css`
    width: 90px;
    fill: ${colors.gray[0]};
  `
)

export const ContextMenuButton = styled.button(
  ({ theme: { colors } }) => css`
    display: flex;
    align-items: center;
    align-self: stretch;
    justify-content: flex-start;
    width: 100%;
    cursor: pointer;
    border-radius: 8px;
    background-color: ${colors.gray[4]};
    color: ${colors.gray[2]};
    border: none;

    &:hover {
      color: ${colors.gray[1]};
      background-color: ${colors.gray[7]};

      svg path {
        stroke: ${colors.gray[1]};
      }

      ${StyledIconText} {
        color: ${colors.gray[1]};
      }
    }
  `
)
