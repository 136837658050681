import { useQuery } from '@tanstack/react-query'

import { getAccountDetails, isAuthenticated } from '../auth'
import { getUserConfig } from '../rest/rest.service'

export const useUserConfig = () => {
  const shouldFetchUser = isAuthenticated() && !getAccountDetails()

  const { isFetching } = useQuery({
    queryKey: ['userConfig'],
    queryFn: () => getUserConfig(),
    enabled: shouldFetchUser,
  })

  return { isFetching }
}
