import { Body1 } from '@opinary/ui-components'
import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { StyledIconText } from '../IconText/index.style'

export const NavWrapper = styled(Body1)(
  ({ theme: { colors } }) => css`
    display: flex;
    grid-area: navigation;
    flex-direction: column;
    justify-content: space-between;
    border-right: 1px solid ${colors.gray[3]};
    padding: 16px 12px;
    color: ${colors.gray[2]};
    min-width: 18px;
    flex-shrink: 0;
    box-sizing: border-box;
  `
)

export const NavList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`

export const StyledLink = styled(NavLink)`
  display: flex;
  text-decoration: none;
  color: inherit;
  border-radius: 8px;
`

export const StyledListItem = styled.li(
  ({ theme: { colors } }) => css`
    border-radius: 8px;

    &:hover,
    & .active {
      background-color: ${colors.gray[7]};

      svg path {
        stroke: ${colors.gray[1]};
      }

      ${StyledIconText} {
        color: ${colors.gray[1]};
      }
    }
  `
)

export const Section = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
`

export const SectionTitle = styled.h4(
  ({ theme: { colors } }) => css`
    font-size: 12px;
    font-weight: normal;
    color: ${colors.gray[2]};
    margin-bottom: 20px;
  `
)
