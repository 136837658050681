export const TRANSLATIONS = {
  TITLE: 'Update your e-mail and become a verified user',
  DESCRIPTION:
    'Share with us emails of all physical users of Opinary’s dashboard. Verified users get access to new features such as:',
  FEATURES_LIST: [
    'automatic password reset',
    'optional multi-factor authentication',
    'coming soon: individual tracking of poll creators',
  ],
  CTA_TEXT: 'Share now',
} as const
