import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { getAccountDetails } from '../../api/auth'
import LogoutIcon from '../../assets/icons/log-out.svg?react'
import { IconText } from '..'
import {
  ContextMenu,
  ContextMenuButton,
  Logo,
  SettingsButton,
  Wrapper,
} from './Header.style.ts'
import { TRANSLATIONS } from './translations'

const Header = () => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false)

  const accountDetails = getAccountDetails()

  const navigate = useNavigate()

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown)
  }

  const items = useMemo(
    () => [
      {
        action: () => navigate('/logout'),
        text: TRANSLATIONS.LOG_OUT_BUTTON,
        Icon: LogoutIcon,
      },
    ],
    [navigate]
  )

  return (
    <Wrapper>
      <Logo data-testid="logo" />
      <SettingsButton
        id="settings"
        type="button"
        onClick={toggleDropdown}
        data-testid="settingsButton"
      >
        {accountDetails?.name.at(0)}
      </SettingsButton>
      {showDropdown && (
        <ContextMenu onClose={() => setShowDropdown(false)}>
          {items.map(({ action, text, Icon }) => (
            <ContextMenuButton key={text} type="button" onClick={action}>
              <IconText Icon={Icon} text={text} />
            </ContextMenuButton>
          ))}
        </ContextMenu>
      )}
    </Wrapper>
  )
}

export default Header
