import { H3 } from '@opinary/ui-components'
import { useNavigate } from 'react-router-dom'

import { useResendLink } from '../hooks'
import { TRANSLATIONS } from '../translations'
import { Button, Subtitle2, Wrapper } from './style'

const LinkExpired = () => {
  const navigate = useNavigate()
  const { resendLink, isPending } = useResendLink()
  const handleResendLinkClick = async () => {
    const { success } = await resendLink()

    if (success) {
      navigate('/check-email')
    }
  }

  return (
    <Wrapper>
      <H3>{TRANSLATIONS.LINK_EXPIRED}</H3>
      <Subtitle2>{TRANSLATIONS.LINK_EXPIRED_MESSAGE}</Subtitle2>
      <Button loading={isPending} onClick={handleResendLinkClick}>
        {TRANSLATIONS.RESEND_LINK}
      </Button>
    </Wrapper>
  )
}

export default LinkExpired
