import { ReactNode, useEffect, useState } from 'react'
import { RouteObject } from 'react-router-dom'

import { Customer } from '../api/rest/types'
import loadRagnarokRoutes from '../pages/Ragnarok'
import loadSkadiRoutes from '../pages/Skadi'
import loadValhallaRoutes from '../pages/Valhalla'
import loadVanaheimRoutes from '../pages/Vanaheim'

const useExternalRoutes = () => {
  const [ragnarokRoutesFn, setRagnarokRoutesFn] =
    useState<
      (
        customerPicker: ReactNode | undefined,
        selectedCustomers: Customer[] | undefined,
        customers: Customer[] | undefined
      ) => RouteObject[]
    >()
  const [skadiRoutesFn, setSkadiRoutesFn] =
    useState<
      (
        customerPicker: ReactNode | undefined,
        selectedCustomers: Customer[] | undefined,
        customers: Customer[] | undefined
      ) => RouteObject[]
    >()
  const [valhallaRoutesFn, setValhallaRoutesFn] =
    useState<
      (
        customerPicker: ReactNode | undefined,
        selectedCustomers: Customer[] | undefined,
        customers: Customer[] | undefined
      ) => RouteObject[]
    >()
  const [vanaheimRoutesFn, setVanaheimRoutesFn] =
    useState<
      (
        customerPicker: ReactNode | undefined,
        selectedCustomers: Customer[] | undefined,
        customers: Customer[] | undefined
      ) => RouteObject[]
    >()

  useEffect(() => {
    const loadRoutes = async () => {
      const [ragnarokRoutes, skadiRoutes, valhallaRoutes, vanaheimRoutes] =
        await Promise.all([
          loadRagnarokRoutes(),
          loadSkadiRoutes(),
          loadValhallaRoutes(),
          loadVanaheimRoutes(),
        ])

      setRagnarokRoutesFn(() => ragnarokRoutes)
      setSkadiRoutesFn(() => skadiRoutes)
      setValhallaRoutesFn(() => valhallaRoutes)
      setVanaheimRoutesFn(() => vanaheimRoutes)
    }

    loadRoutes()
  }, [])

  return {
    ragnarokRoutesFn,
    skadiRoutesFn,
    valhallaRoutesFn,
    vanaheimRoutesFn,
  }
}

export default useExternalRoutes
