export const TRANSLATIONS = {
  TITLE: 'Enter the 6 character code',
  SUBTITLE:
    'Please check your e-mail account for the verification code we just sent you and enter that code into the box',
  VERIFY: 'Verify',
  CODE_PLACEHOLDER: 'Enter code here',
  RESEND: 'Resend code',
  RESEND_SUCCESS: '{attempts} attempts remaining.',
  SEARCH_INFO: 'Can’t find it? Please check your spam folder',
  SUCCESS_VERIFY: 'Login successful',
  ERROR: {
    SERVER_ERROR: 'Something went wrong. Please try again later',
  },
}
