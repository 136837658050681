import { Body1, Logo } from '@opinary/ui-components'
import styled, { css } from 'styled-components'

export const PageContainer = styled(Body1)(
  ({ theme }) => css`
    background-color: ${theme.colors.gray[6]};
    color: ${theme.colors.gray[1]};
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: stretch;
    align-items: center;
    line-height: 17px;
    padding-top: 78px;
    box-sizing: border-box;
  `
)

export const OpinaryLogo = styled(Logo)`
  max-width: 108px;
  padding-bottom: 40px;
`
