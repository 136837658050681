const loadVanaheimRoutes = async () => {
  try {
    const { default: getVanaheimRoutes } = await import('vanaheim/getRoutes')
    return getVanaheimRoutes
  } catch {
    return () => []
  }
}

export default loadVanaheimRoutes
