export const TRANSLATIONS = {
  LOG_IN_BUTTON: 'Sign in',
  LOGIN_NAME: 'Username (may be the same as e-mail)',
  LOGIN_PLACEHOLDER: 'Enter your username or email',
  PASSWORD: 'Password',
  PASSWORD_PLACEHOLDER: 'Enter your password',
  ERROR: {
    REQUIRED: '*This is a required field',
    SERVER_ERROR: 'Something went wrong. Please try again later',
  },
  TITLE: 'Sign in to your account',
}
