import { Outlet } from 'react-router-dom'

import { Header, Navigation } from '../../components'
import { Container, Main } from './index.style'

const Layout = () => {
  return (
    <Main data-testid="layout">
      <Header />
      <Navigation />
      <Container>
        <Outlet />
      </Container>
    </Main>
  )
}

export default Layout
