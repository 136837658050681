import axios from 'axios'

import { setAccountDetails } from '../auth.ts'
import { GATEWAY_API_URL } from '../constants.ts'
import { CustomerResponse, UserConfig } from './types.ts'

export async function logoutRequest() {
  const API_LOGOUT_URL = `${GATEWAY_API_URL}/v1/logout`
  const response = await axios.post(
    API_LOGOUT_URL,
    {},
    { withCredentials: true }
  )

  return response.data
}

export async function getUserConfig() {
  const API_USER_CONFIG_URL = `${GATEWAY_API_URL}/v1/user-config`

  const response = await axios.get<UserConfig>(API_USER_CONFIG_URL, {
    withCredentials: true,
  })

  setAccountDetails(response.data.account)

  return response.data
}

export const getCustomers = async () => {
  const CUSTOMERS_URL = `${GATEWAY_API_URL}/v1/customers`
  const response = await axios.get<CustomerResponse>(CUSTOMERS_URL)

  return response.data
}
