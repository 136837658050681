import { H3, Notification, TextField } from '@opinary/ui-components'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Form, SubmitButton } from '../../components/AuthForm'
import { OTPModal } from '../../components/index.ts'
import { ERROR_CODES } from '../../constants.ts'
import { MAX_CODE_LENGTH, POST_VERIFY_DELAY } from './constants.ts'
import useResend from './hooks/useResend'
import useVerify from './hooks/useVerify.ts'
import { Info, Logo, PageContainer, Subtitle } from './index.style'
import ResendCode from './ResendCode'
import { TRANSLATIONS } from './translations.ts'
import { NotificationMsg, ResendResponse } from './types.ts'

type Props = {
  onLogin: () => void
}

export const Verify = ({ onLogin }: Props) => {
  const [code, setCode] = useState('')
  const [notification, setNotification] = useState<NotificationMsg | null>(null)
  const navigate = useNavigate()

  const { verify, isLoading, error, clearErrors } = useVerify({
    onSuccess: _data => {
      onLogin()
      setNotification({ message: TRANSLATIONS.SUCCESS_VERIFY, type: 'success' })
      setTimeout(() => {
        navigate('/library')
      }, POST_VERIFY_DELAY)
    },
    onError: errorMsg => {
      setNotification({ message: errorMsg, type: 'error' })
    },
  })

  const {
    resend,
    error: resendError,
    clearErrors: clearResendErrors,
  } = useResend({
    onSuccess: data => {
      clearResendErrors()
      handleResend(data)
    },
    onError: errorMsg => {
      setNotification({ message: errorMsg, type: 'error' })
    },
  })

  const isDisabled =
    isLoading || error || !code || code.length !== MAX_CODE_LENGTH

  const onSubmit = () => verify({ code })
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearErrors()
    setNotification(null)
    setCode(event.target.value || '')
  }

  const handleResend = (data: ResendResponse) => {
    const { attempts_left } = data
    setNotification({
      type: 'info',
      message: TRANSLATIONS.RESEND_SUCCESS.replace(
        '{attempts}',
        attempts_left.toString()
      ),
    })
  }

  const isUserBlocked =
    resendError?.code === ERROR_CODES.USER_BANNED ||
    error?.code === ERROR_CODES.USER_BANNED

  return (
    <PageContainer>
      <Logo />
      <H3>{TRANSLATIONS.TITLE}</H3>
      <Subtitle>{TRANSLATIONS.SUBTITLE}</Subtitle>

      <Form noValidate>
        <TextField
          id="code"
          data-testid="code"
          type="text"
          success={notification?.type === 'success'}
          maxLength={MAX_CODE_LENGTH}
          value={code}
          placeholder={TRANSLATIONS.CODE_PLACEHOLDER}
          onChange={handleChange}
          hasError={!!error}
        />

        <ResendCode onResend={resend} />

        <SubmitButton
          data-testid="verify"
          onClick={onSubmit}
          disabled={isDisabled}
        >
          {TRANSLATIONS.VERIFY}
        </SubmitButton>

        {notification && (
          <Notification
            type={notification?.type}
            message={notification?.message}
          />
        )}

        <Info>{TRANSLATIONS.SEARCH_INFO}</Info>
      </Form>

      <OTPModal isOpen={isUserBlocked} />
    </PageContainer>
  )
}

export default Verify
