import { Body1 } from '@opinary/ui-components'
import { styled } from 'styled-components'

export const Wrapper = styled(Body1)`
  margin-top: -12px;
  text-align: left;
  display: flex;
  align-self: flex-start;
`

export const BoldText = styled.span`
  font-weight: 700;
`

export const TextButton = styled.button`
  background: none;
  border: none;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.gray[1]};
  cursor: pointer;
  padding: 0;
`
