import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  text-align: center;
  line-height: 29.3px;
`

export const Header = styled.h2`
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  margin: 0;
`

export const Description = styled.p`
  margin: 0;
  font-size: 18px;
`

export const DescriptionSeparator = styled.p`
  font-size: 16px;
  font-weight: 300;
  margin: 4px 0;
`
