import { Account } from '../types'

export const setAccountDetails = (account?: Account) => {
  if (account) localStorage.setItem('account', JSON.stringify(account))
}
export const clearAccountDetails = () => {
  localStorage.removeItem('account')
}

export const getAccountDetails = (): Account | null => {
  const account = localStorage.getItem('account')
  return account ? (JSON.parse(account) as Account) : null
}

export const getPermissions = () => {
  const accountDetails = getAccountDetails()
  return accountDetails?.scopes || []
}

export const getCookie = (name: string) => {
  const cookieKey = `${name}=`
  const cookies = window.document.cookie.split(';')

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim()
    if (cookie.indexOf(cookieKey) == 0)
      return cookie.substring(cookieKey.length, cookie.length)
  }

  return ''
}

export const getAuthToken = () => getCookie('auth')

export const isAuthenticated = () => {
  return !!getAuthToken()
}
