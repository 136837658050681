import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'

import { Error } from '../../../types'
import { resendRequest } from '../rest.service'
import { TRANSLATIONS } from '../translations'
import { ResendResponse } from '../types.ts'

type Props = {
  onSuccess: (data: ResendResponse) => void
  onError?: (error: any) => void
}

const useResend = ({ onSuccess, onError }: Props) => {
  const [error, setError] = useState<Error | null>(null)
  const { mutate: resend, isPending } = useMutation<ResendResponse, any, void>({
    mutationKey: ['resend'],
    mutationFn: () => resendRequest(),
    onError: error => {
      setError(error.response.data.errors || TRANSLATIONS.ERROR.SERVER_ERROR)
      onError?.(error.response.data.errors || TRANSLATIONS.ERROR.SERVER_ERROR)
    },
    onSuccess: data => {
      onSuccess(data)
    },
  })

  return {
    resend,
    isLoading: isPending,
    error,
    clearErrors: () => setError(null),
  }
}

export default useResend
