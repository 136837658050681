import {
  Analytics,
  AudiencesIcon,
  MultichoiceIcon,
  SliderIcon,
  SpeedoIcon,
} from '@opinary/ui-components'

import { PERMISSIONS } from '../../constants.ts'
import { Item, Subsection } from './CreateMenu/types.ts'
import { TRANSLATIONS } from './translations'

const CREATE_SPEEDOMETER_TOOL = '/library/poll/create/one-axis'
const CREATE_MULTI_CHOICE_TOOL = '/library/poll/create/ranking'
const CREATE_SLIDER_TOOL = '/library/poll/create/slider'

const CREATE_CAMPAIGN = '/audience/campaigns/create'
const CREATE_CONVERSION_CAMPAIGN = '/audience/conversion-campaigns/create'

const TOOL_POLL_CREATOR_ITEMS: Item[] = [
  {
    text: TRANSLATIONS.SPEEDOMETER,
    Icon: SpeedoIcon,
    to: CREATE_SPEEDOMETER_TOOL,
    permissions: [],
  },
  {
    text: TRANSLATIONS.MULTI_CHOICE,
    Icon: MultichoiceIcon,
    to: CREATE_MULTI_CHOICE_TOOL,
    permissions: [],
  },
  {
    text: TRANSLATIONS.SLIDER,
    Icon: SliderIcon,
    to: CREATE_SLIDER_TOOL,
    permissions: [],
  },
]

const CONTEXT_MENU_CAMPAIGNS_ITEMS = [
  {
    text: TRANSLATIONS.AUDIENCES as string,
    Icon: AudiencesIcon,
    to: CREATE_CAMPAIGN,
    permissions: [PERMISSIONS.AUDIENCE],
  },
  {
    text: TRANSLATIONS.CONVERSIONS,
    Icon: Analytics,
    to: CREATE_CONVERSION_CAMPAIGN,
    permissions: [],
  },
]

export const createMenuItems: Subsection[] = [
  {
    title: TRANSLATIONS.POLL_SUBHEADER,
    items: TOOL_POLL_CREATOR_ITEMS,
  },
  {
    title: TRANSLATIONS.CAMPAIGNS_SUBHEADER,
    items: CONTEXT_MENU_CAMPAIGNS_ITEMS,
  },
]
