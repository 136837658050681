import {
  Modal as ModalComponent,
  Subtitle1,
  ToolButton,
} from '@opinary/ui-components'
import styled, { css } from 'styled-components'

export const Modal = styled(ModalComponent)`
  width: 600px;
`

export const Title = styled(Subtitle1)(
  ({ theme }) => css`
    color: ${theme.colors.gray[0]};
    font-size: 24px;
    margin-top: 20px;
  `
)

export const Description = styled.p(
  ({ theme }) => css`
    color: ${theme.colors.gray[2]};
    font-size: 16px;
    line-height: 25px;
  `
)

export const List = styled.ul`
  margin-bottom: 56px;
`

export const ListItem = styled.li(
  ({ theme }) => css`
    margin-bottom: 10px;
    color: ${theme.colors.gray[2]};
  `
)

export const CtaButton = styled(ToolButton).attrs({ variant: 'primary' })`
  width: 100%;
  font-size: 16px;
  height: 40px;
`
