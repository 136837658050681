import styled, { css } from 'styled-components'

export const Wrapper = styled.div(
  ({ theme: { colors } }) => css`
    width: 300px;
    color: ${colors.gray[2]};
    font-size: 12px;
  `
)

export const List = styled.ul`
  margin-top: 4px;
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const ListItem = styled.li<{ $fulfilled: boolean }>(
  ({ $fulfilled, theme: { colors } }) => css`
    color: ${$fulfilled ? colors.gray[3] : colors.gray[2]};
    text-decoration: ${$fulfilled ? 'line-through' : 'none'};
  `
)
