import { ChevronUp } from '@opinary/ui-components'
import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  margin-bottom: 16px;
`

export const Button = styled.button(
  ({ theme: { colors } }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    background-color: ${colors.orange[0]};
    color: ${colors.gray[6]};
    outline: none;
    appearance: none;
    border-radius: 8px;
    border: 1px solid ${colors.orange[2]};
    padding: 8px 4px 8px 16px;
    cursor: pointer;

    &:hover {
      background-color: ${colors.orange[2]};
    }
  `
)

export const Title = styled.span(
  ({ theme: { colors } }) => css`
    display: inline-flex;
    height: 33px;
    align-items: center;
    flex-grow: 1;
    border-right: 1px solid ${colors.gray[3]};
    font-size: 16px;
    font-weight: 500;
  `
)

export const ChevronDown = styled(ChevronUp)<{ $open: boolean }>`
  transition: transform 0.3s ease-in-out;
  transform: ${({ $open }) => ($open ? 'rotateX(0deg)' : 'rotateX(180deg)')}
    translateY(-3px);

  padding-left: 4px;
  width: 29px;
  height: 15px;
`
