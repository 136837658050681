const loadRagnarokRoutes = async () => {
  try {
    const { default: getRagnarokRoutes } = await import('ragnarok/getRoutes')
    return getRagnarokRoutes
  } catch {
    return () => []
  }
}

export default loadRagnarokRoutes
