import { useQuery } from '@tanstack/react-query'

import { ApiError } from '../../../types'
import { verifyToken } from '../rest.service'
import { VerifyTokenResponse } from '../types'

const useVerifyToken = (token: string) => {
  const { data, isPending } = useQuery<VerifyTokenResponse, ApiError>({
    queryKey: ['validateToken'],
    queryFn: () => verifyToken(token),
  })

  return { data, isPending }
}

export default useVerifyToken
