import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root,
  main {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
  }

  body {
    font-family: 'Inter', sans-serif;
    background-color: ${({ theme }) => (theme as any).colors.gray[5]};
  }
`

export default GlobalStyle
