import axios, { AxiosError } from 'axios'

//exported for testing purposes only
export const onRejected = (error: AxiosError) => {
  if (
    (error?.response?.status === 401 || error?.response?.status === 403) &&
    window.location.pathname !== '/logout'
  )
    window.location.assign('/logout')
  return Promise.reject(error)
}

const setupAuthMiddleware = () => {
  axios.defaults.withCredentials = true
  axios.interceptors.response.use(response => response, onRejected)
}

export default setupAuthMiddleware
