const loadSkadiRoutes = async () => {
  try {
    const { default: getSkadiRoutes } = await import('skadi/getRoutes')
    return getSkadiRoutes
  } catch {
    return () => []
  }
}

export default loadSkadiRoutes
