import { FC, SVGProps } from 'react'

import { IconWrapper, ListItemText, StyledIconText } from './index.style'

type IconTextProps = {
  Icon: FC<SVGProps<SVGSVGElement>>
  text: string
}

const IconText = ({ Icon, text }: IconTextProps) => {
  return (
    <StyledIconText>
      <IconWrapper>
        <Icon />
      </IconWrapper>
      <ListItemText>{text}</ListItemText>
    </StyledIconText>
  )
}

export default IconText
