import { CircularSpinner } from '@opinary/ui-components'
import { useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { clearAccountDetails } from '../../api/auth'
import { logoutRequest } from '../../api/rest/rest.service'
import { SpinnerWrapper } from './index.style'

const Logout = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const handleLogout = async () => {
    try {
      await logoutRequest()
      clearAccountDetails()
      queryClient.resetQueries()
    } catch (err) {
      console.log('Logout Error')
    }
  }

  useEffect(() => {
    handleLogout().finally(() => navigate('/login'))
  })

  return (
    <SpinnerWrapper>
      <CircularSpinner circleColor="#F9A524" radius={25} strokeWidth={5} />
    </SpinnerWrapper>
  )
}

export default Logout
