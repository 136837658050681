import { Logo } from '@opinary/ui-components'
import styled, { css } from 'styled-components'

export const OpinaryLogo = styled(Logo)(
  ({ theme: { colors } }) => css`
    fill: ${colors.orange[2]};
    max-width: 110px;
    margin-bottom: 48px;
  `
)

export const Label = styled.label(
  ({ theme: { colors } }) => css`
    display: block;
    width: 100%;
    color: ${colors.gray[2]};
    font-size: 14px;
    & > div:first-child {
      margin-top: 4px;
    }
  `
)
