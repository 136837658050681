import { useFloatingNotification } from '@opinary/ui-components'
import { useMutation } from '@tanstack/react-query'

import { TRANSLATIONS } from '../../../translations'
import { ApiError } from '../../../types'
import { resendLinkRequest } from '../rest.service'
import { ResendLinkResponse } from '../types'

const useResendLink = () => {
  const notify = useFloatingNotification()

  const { mutateAsync, isPending, data } = useMutation<
    ResendLinkResponse,
    ApiError
  >({
    mutationKey: ['resendLink'],
    mutationFn: () => resendLinkRequest(),
    onError: error => {
      notify({
        message:
          error.response.data.errors?.map(({ message }) => message) ??
          TRANSLATIONS.SERVER_ERROR,
        type: 'error',
      })
    },
  })

  return { resendLink: mutateAsync, isPending, data }
}

export default useResendLink
