import { useEffect, useState } from 'react'

import { RESEND_COUNTDOWN } from './constants'
import { BoldText, TextButton, Wrapper } from './index.style'
import { TRANSLATIONS } from './translations.ts'

type Props = {
  onResend(): void
}

export const ResendCode = ({ onResend }: Props) => {
  const [countdown, setCountdown] = useState(RESEND_COUNTDOWN)

  useEffect(() => {
    const timer = setTimeout(() => {
      setCountdown(countdown - 1)
    }, 1000)

    return () => clearTimeout(timer)
  }, [countdown])

  const resetTimer = () => {
    setCountdown(RESEND_COUNTDOWN)
  }

  const handleResendCode = () => {
    onResend()
    resetTimer()
  }

  const renderResend = () => {
    if (countdown > 0) {
      return (
        <span>
          {TRANSLATIONS.RESEND_COUNTDOWN}
          <BoldText>{`${countdown} ${TRANSLATIONS.RESEND_UNIT}`}</BoldText>
        </span>
      )
    }

    return (
      <TextButton onClick={handleResendCode}>{TRANSLATIONS.RESEND}</TextButton>
    )
  }

  return <Wrapper $fontWeight="medium">{renderResend()}</Wrapper>
}

export default ResendCode
