export const TRANSLATIONS = {
  RESET_PASSWORD: 'Reset Password',
  NEW_PASSWORD: 'New Password',
  CONFIRM_PASSWORD: 'Confirm Password',
  RESET_PASSWORD_BUTTON: 'Reset password',
  PASSWORD_REQUIREMENTS: 'Password requirements',
  ERROR_PASSWORDS_DONT_MATCH: 'Passwords should match',
  ERROR_PASSWORD_TOO_SHORT: 'Password should be at least 8 characters',
  ERROR_SPECIAL_CHARACTER: 'Must contain special characters like @ .+ - _',
  ERROR_NUMBER: 'Must contain at least numerical character like 1,2,3',
  ERROR_CASE: 'Must contain upper case and lower case letters',
  PASSWORD_RESET_SUCCESS: 'Password has been reset. Log in to your account.',
  LINK_EXPIRED: 'Link Expired!',
  LINK_EXPIRED_MESSAGE: 'The link was unused for more than 30 mins.',
  RESEND_LINK: 'Resend Link',
}
