import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'

import { setAccountDetails } from '../../../api/auth.ts'
import { Error } from '../../../types.ts'
import { verifyRequest } from '../rest.service'
import { TRANSLATIONS } from '../translations.ts'
import { VerifyResponse } from '../types.ts'

type VerifyForm = {
  code: string
}

type Props = {
  onSuccess: (data: VerifyResponse) => void
  onError?: (error: any) => void
}

const useVerify = ({ onSuccess, onError }: Props) => {
  const [error, setError] = useState<Error | null>(null)
  const { mutate: verify, isPending } = useMutation<
    VerifyResponse,
    any,
    VerifyForm
  >({
    mutationKey: ['verify'],
    mutationFn: ({ code }) => verifyRequest(code),
    onError: error => {
      onError?.(error.response.data.errors || TRANSLATIONS.ERROR.SERVER_ERROR)
      setError(error.response.data)
    },
    onSuccess: data => {
      setAccountDetails(data.account)
      onSuccess(data)
    },
  })

  return {
    verify,
    isLoading: isPending,
    error,
    clearErrors: () => setError(null),
  }
}

export default useVerify
