import { ModalBlocker, theme, Warning } from '@opinary/ui-components'

import {
  Content,
  Description,
  DescriptionSeparator,
  Header,
} from './index.style'
import { TRANSLATIONS } from './translations'

type Props = {
  isOpen?: boolean
}

const OTPModal = ({ isOpen }: Props) => {
  return (
    <ModalBlocker isOpen={isOpen}>
      <Content data-testid="content">
        <Warning fill={theme.colors.orange[0]} />
        <Header>{TRANSLATIONS.OTP_HEADER}</Header>
        <Description>
          {TRANSLATIONS.LIMIT_EXCEEDED}
          <br /> {TRANSLATIONS.TRY_AGAIN}
        </Description>
        <DescriptionSeparator>{TRANSLATIONS.OR}</DescriptionSeparator>
        <Description>{TRANSLATIONS.CONTACT_OPINARY}</Description>
      </Content>
    </ModalBlocker>
  )
}

export default OTPModal
