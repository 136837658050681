import { Errors } from './types'

export const validatePassword = (
  password: string,
  confirmPassword: string
): Errors => {
  const errors: Errors = {
    length: password.length >= 8,
    case: /[a-z]/.test(password) && /[A-Z]/.test(password),
    specialChar: /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password),
    number: /\d/.test(password),
    match: password === confirmPassword,
  }

  return errors
}
