export const PERMISSIONS = {
  ANALYTICS: 'analytics',
  LIBRARY: 'library',
  ADMIN: 'admin',
  AUTOMATION: 'automation',
  REVENUE: 'revenue',
  API: 'api',
  DMP: 'dmp',
  CONVERSIONS: 'conversions',
  AUDIENCE: 'audience',
}

export enum ERROR_CODES {
  INVALID_CREDENTIALS = 401,
  REQUIRED = 400,
  USER_BANNED = 429,
}
