import { createContext, PropsWithChildren } from 'react'

import { Customer } from '../api/rest/types.ts'

export type AppContextType = {
  selectedCustomers: Customer[]
  customers: Customer[]
  setSelectedCustomers: (data: Customer[]) => void
}

export const AppContext = createContext<AppContextType>({
  selectedCustomers: [],
  customers: [],
  setSelectedCustomers: () => {},
})

const AppProvider = ({
  children,
  customers,
  selectedCustomers,
  setSelectedCustomers,
}: PropsWithChildren<AppContextType>) => {
  return (
    <AppContext.Provider
      value={{ selectedCustomers, customers, setSelectedCustomers }}
    >
      {children}
    </AppContext.Provider>
  )
}

export default AppProvider
