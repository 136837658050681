import { FontStyles, hasAccess, theme } from '@opinary/ui-components'
import { CirclingAvatar } from '@opinary/ui-components'
import { useEffect, useState } from 'react'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import { getAccountDetails, getPermissions } from './api/auth.ts'
import setupAuthMiddleware from './api/axiosMiddleware.ts'
import useCustomers from './api/hooks/useCustomers.ts'
import { Customer } from './api/rest/types.ts'
import CustomerPicker from './components/CustomerPicker'
import PostLoginModal from './components/PostLoginModal'
import RequireAuth from './components/RequireAuth'
import { PERMISSIONS } from './constants.ts'
import AppProvider from './context/AppContext.tsx'
import GlobalStyle from './global.style'
import useExternalRoutes from './hooks/useExternalRoutes.ts'
import { Layout, Login, Verify } from './pages'
import Logout from './pages/Logout'
import ResetPassword from './pages/ResetPassword/index.ts'

setupAuthMiddleware()

const UNAUTHENTICATED_PAGES = {
  login: '/login',
  verify: '/verify',
  resetPassword: '/reset-password',
}

const App = () => {
  const [selectedCustomers, setSelectedCustomers] = useState<Customer[]>([])
  const [isPostLoginModalOpen, setIsPostLoginModalOpen] = useState(false)
  const {
    ragnarokRoutesFn,
    skadiRoutesFn,
    valhallaRoutesFn,
    vanaheimRoutesFn,
  } = useExternalRoutes()

  const accountDetails = getAccountDetails()
  const permissions = getPermissions()
  const { data, refetch } = useCustomers()

  const isAdmin = hasAccess(permissions, [PERMISSIONS.ADMIN])

  const handleModalClose = () => {
    setIsPostLoginModalOpen(false)
  }

  useEffect(() => {
    if (data?.results?.length && !isAdmin) {
      setSelectedCustomers(data.results)
    }
  }, [data?.results, isAdmin])

  const CustomerPickerComponent = (
    <CustomerPicker
      setCustomers={setSelectedCustomers}
      availableCustomers={data?.results || []}
      selectedCustomers={selectedCustomers}
    />
  )

  if (
    !ragnarokRoutesFn ||
    !skadiRoutesFn ||
    !valhallaRoutesFn ||
    !vanaheimRoutesFn
  ) {
    return <CirclingAvatar />
  }

  return (
    <AppProvider
      selectedCustomers={selectedCustomers}
      setSelectedCustomers={setSelectedCustomers}
      customers={data?.results ?? []}
    >
      <ThemeProvider theme={theme}>
        <FontStyles />
        <GlobalStyle />
        <PostLoginModal
          isOpen={isPostLoginModalOpen}
          onClose={handleModalClose}
        />
        <main data-testid="main">
          <RouterProvider
            router={createBrowserRouter([
              {
                path: '/',
                element: (
                  <RequireAuth>
                    <Layout />
                  </RequireAuth>
                ),
                children: [
                  {
                    index: true,
                    element: <Navigate to="library" replace />,
                  },
                  {
                    path: 'audience',
                    children: ragnarokRoutesFn(
                      CustomerPickerComponent,
                      selectedCustomers,
                      data?.results ?? []
                    ),
                  },
                  {
                    path: 'dashboard',
                    children: skadiRoutesFn(
                      CustomerPickerComponent,
                      selectedCustomers,
                      data?.results ?? []
                    ),
                  },
                  {
                    path: 'library',
                    children: valhallaRoutesFn(
                      CustomerPickerComponent,
                      selectedCustomers,
                      data?.results ?? []
                    ),
                  },
                  {
                    path: 'customer',
                    children: vanaheimRoutesFn(
                      CustomerPickerComponent,
                      selectedCustomers,
                      data?.results ?? []
                    ),
                  },
                ],
              },
              {
                path: UNAUTHENTICATED_PAGES.login,
                element: accountDetails ? (
                  <Navigate to="/library" replace />
                ) : (
                  <Login
                    setIsPostLoginModalOpen={setIsPostLoginModalOpen}
                    onLogin={refetch}
                  />
                ),
              },
              {
                path: UNAUTHENTICATED_PAGES.verify,
                element: accountDetails ? (
                  <Navigate to="/library" replace />
                ) : (
                  <Verify onLogin={refetch} />
                ),
              },
              {
                path: '/logout',
                element: <Logout />,
              },
              {
                path: UNAUTHENTICATED_PAGES.resetPassword,
                element: accountDetails ? (
                  <Navigate to="/library" replace />
                ) : (
                  <ResetPassword />
                ),
              },
            ])}
          />
        </main>
      </ThemeProvider>
    </AppProvider>
  )
}

export default App
