export const TRANSLATIONS = {
  CREATOR_LINK: 'Create',
  POLL_SUBHEADER: 'Polls',
  SPEEDOMETER: 'Speedometer',
  MULTI_CHOICE: 'Multiple choice',
  SLIDER: 'Slider',
  CAMPAIGNS_SUBHEADER: 'Campaigns',
  AUDIENCES: 'Audiences',
  CONVERSIONS: 'Conversions',
} as const
