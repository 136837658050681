import { ContextMenu } from '@opinary/ui-components'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { StyledIconText } from '../../IconText/index.style.ts'

export const Menu = styled(ContextMenu)`
  left: 0;
`

export const SubsectionWrapper = styled.div(
  ({ theme }) => css`
    & + & {
      border-top: 1px solid ${theme.colors.gray[3]};
      padding-top: 12px;
      margin-top: 12px;
    }
  `
)

export const SubsectionTitle = styled.h5`
  margin: 0 0 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`

export const MenuItemLink = styled(Link)(
  ({ theme: { colors } }) => css`
    display: flex;
    align-items: center;
    align-self: stretch;
    justify-content: flex-start;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    cursor: pointer;
    border-radius: 8px;
    background-color: ${colors.gray[4]};
    color: ${colors.gray[2]};
    border: none;
    text-decoration: none;

    &:hover {
      color: ${colors.gray[1]};
      background-color: ${colors.gray[7]};

      svg path {
        stroke: ${colors.gray[1]};
      }

      ${StyledIconText} {
        color: ${colors.gray[1]};
      }
    }
  `
)
