import {
  CtaButton,
  Description,
  List,
  ListItem,
  Modal,
  Title,
} from './PostLoginModal.styles.ts'
import { TRANSLATIONS } from './translations.ts'

const EMAIL_COLLECTION_URL = 'https://mailchi.mp/opinary/emailverification'

type Props = {
  isOpen: boolean
  onClose: () => void
}

const PostLoginModal = ({ isOpen, onClose }: Props) => {
  const handleButtonClick = () => {
    window.open(EMAIL_COLLECTION_URL, '_blank')?.focus()
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnClickOutside={false}>
      <Title>{TRANSLATIONS.TITLE}</Title>
      <Description>{TRANSLATIONS.DESCRIPTION}</Description>
      <List>
        {TRANSLATIONS.FEATURES_LIST.map(feature => (
          <ListItem key={feature}>{feature}</ListItem>
        ))}
      </List>
      <CtaButton onClick={handleButtonClick}>{TRANSLATIONS.CTA_TEXT}</CtaButton>
    </Modal>
  )
}

export default PostLoginModal
