import axios from 'axios'

import { API_LOGIN_SCOPE_URL, GATEWAY_API_URL } from '../../api/constants'
import { LoginResponse } from './types'

export async function loginRequest(login: string, password: string) {
  const API_LOGIN_URL = `${GATEWAY_API_URL}/v1/login`
  const response = await axios.post<LoginResponse>(
    API_LOGIN_URL,
    {
      login,
      password,
      scopes: [API_LOGIN_SCOPE_URL],
    },
    { withCredentials: true }
  )

  return response.data
}
