import { H3 } from '@opinary/ui-components'
import { PasswordField, Tooltip } from '@opinary/ui-components'
import { Notification } from '@opinary/ui-components'
import { CirclingAvatar } from '@opinary/ui-components'
import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { Form, SubmitButton } from '../../components/AuthForm'
import { PageContainer } from '../Login/index.style'
import ErrorMessage from './ErrorMessage'
import { useVerifyToken } from './hooks'
import LinkExpired from './LinkExpired'
import { Label, OpinaryLogo } from './style'
import { TRANSLATIONS } from './translations'
import { validatePassword } from './utils'

const INITIAL_ERRORS = {
  length: true,
  case: true,
  specialChar: true,
  number: true,
  match: true,
}

export const POST_RESET_DELAY = 1000

const ResetPassword = () => {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const [notification, setNotification] = useState<string>()
  const [errors, setErrors] = useState(INITIAL_ERRORS)

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { isPending, data } = useVerifyToken(searchParams.get('token') ?? '')

  const handleSubmit = async () => {
    const validations = validatePassword(password, confirmPassword)
    setErrors(validations)

    if (Object.values(validations).some(error => !error)) {
      return
    }

    // API call to reset password
    setNotification(TRANSLATIONS.PASSWORD_RESET_SUCCESS)

    setTimeout(() => {
      setNotification(undefined)
      navigate('/login')
    }, POST_RESET_DELAY)
  }

  if (isPending) {
    return <CirclingAvatar />
  }

  return (
    <PageContainer>
      <OpinaryLogo />
      {data?.success === false ? (
        <LinkExpired />
      ) : (
        <>
          <H3>{TRANSLATIONS.RESET_PASSWORD}</H3>
          <Form noValidate>
            <Label htmlFor="password">
              {TRANSLATIONS.NEW_PASSWORD}
              <Tooltip
                placement="rightTop"
                trigger="contextMenu"
                visible={Object.values(errors).some(error => !error)}
                overlay={<ErrorMessage errors={errors} />}
                destroyTooltipOnHide
              >
                <PasswordField
                  id="password"
                  type="password"
                  value={password}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setPassword(event.target.value)
                    setErrors(INITIAL_ERRORS)
                  }}
                />
              </Tooltip>
            </Label>
            <PasswordField
              id="confirmPassword"
              type="password"
              required
              label={TRANSLATIONS.CONFIRM_PASSWORD}
              value={confirmPassword}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setConfirmPassword(event.target.value)
                setErrors(INITIAL_ERRORS)
              }}
            />

            <SubmitButton onClick={handleSubmit}>
              {TRANSLATIONS.RESET_PASSWORD_BUTTON}
            </SubmitButton>
            {notification && (
              <Notification type="success" message={notification} />
            )}
          </Form>
        </>
      )}
    </PageContainer>
  )
}

export default ResetPassword
