import axios from 'axios'

import { GATEWAY_API_URL } from '../../api/constants'

export async function verifyRequest(code: string) {
  const API_VERIFY_URL = `${GATEWAY_API_URL}/v1/verify-mfa-code`
  const response = await axios.post(
    API_VERIFY_URL,
    { code },
    { withCredentials: true }
  )

  return response.data
}

export async function resendRequest() {
  const API_RESEND_CODE_URL = `${GATEWAY_API_URL}/v1/resend-mfa-code`

  const response = await axios.get(API_RESEND_CODE_URL, {
    withCredentials: true,
  })

  return response.data
}
